<template>
  <div class="pageContol studentList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">系统管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">考官管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox">
            <div title="姓名" class="searchboxItem ci-full">
              <span class="itemLabel">姓名:</span>
              <el-input v-model="retrievalData.officerName" type="text" size="small" placeholder="姓名" clearable />
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
            <el-button type="primary" class="bgc-bv" round @click="to_edit">新增</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
              <el-table-column label="姓名" align="left" show-overflow-tooltip prop="officerName" minWidth="80" />
              <el-table-column label="身份证号" align="left" show-overflow-tooltip prop="idCard" minWidth="120" />
              <el-table-column label="联系方式" align="left" show-overflow-tooltip prop="mobile" minWidth="100" />
              <el-table-column label="创建时间" align="left" show-overflow-tooltip prop="createTime" minWidth="140" />
              <el-table-column label="操作" align="center" width="180px" fixed="right">
                <template slot-scope="scope">
                  <el-button type="text" style="padding: 0px 5px" size="mini" @click="to_edit(scope.row)">编辑</el-button>
                  <el-button type="text" style="padding: 0px 5px" size="mini" @click="to_delete(scope.row)">删除</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "systemManage_supervise",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      retrievalData: {
        officerName: "", // 姓名
      },
    };
  },
  created() {

  },
  watch: {},
  computed: {},
  mounted() { },
  methods: {
    // 获取 - 列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        ...this.retrievalData
      };
      this.doFetch({
        url: "/biz/officer/pageList",
        params,
        pageNum,
      }, true, 10);
    },
    // 编辑
    to_edit(row) {
      this.$router.push({
        path: "/practicalAssessmentSystem/systemManage/supervise_addAndedit",
        query: {
          officerId: row.officerId
        }
      })
    },
    // 删除
    to_delete(row) {
      this.$post("/biz/officer/delete", {
        officerId: row.officerId
      }, 3000, true, 10).then((res) => {
        this.$message.success('操作成功');
        this.getData(this.pageNum);
      });
    },
  },
};
</script>
<style lang="less" scoped></style>